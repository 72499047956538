<template>
    <div>
        <div class="lotteryKind5" v-if="lotteryKind === '5'">
            <canvas id="lotteryKind5"></canvas>
        </div>
        <div class="lotteryKind7" v-else-if="lotteryKind === '7'">
            <canvas id="lotteryKind7"></canvas>
        </div>
        <div class="btnGroup">
            <div :class="[fold?'':fold1,btnGroup1]">
                <ul>
                    <li v-for="(item,index) in colors" :key="index" @click="checkColor(item)"><span></span></li>
                </ul>
            </div>
            <div class="btn" @click="unfold">
                <img src="../../assets/images/btnbox.png" alt="展开" /> 
            </div>
        </div>
        <div :class="[btnGroup2,fold?'':fold2]">
            <div class="line" @click="chooseLine('straight')">
                <img src="../../assets/images/straight.png" alt="直线">
            </div>
            <div class="line" @click="chooseLine('curve')">
                <img src="../../assets/images/curve.png" alt="曲线">
            </div>
        </div>
        <div class="delete">
            <div class="back" @click="back">
                <img src="../../assets/images/back.png" alt="回退">
            </div>
            <div class="remove" @click="remove">
                <img src="../../assets/images/remove.png" alt="删除全部">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            lotteryKind:'',
            header:['期号','值','A','B','C','D','E'],
            header7:['期号','值','A','B','C','D','E','F','G'],
            list:[],
            deviceWidth:500,
            startObj:{},
            endObj:{},
            fold:true,
            fold1:'fold1',
            btnGroup1:'btnGroup1',
            fold2:'fold2',
            btnGroup2:'btnGroup2',
            color:'#2715cb',
            lineType:'straight',
            rectW:0,
            operator:[],
            colors:['#2715cb','#3366ff','#33cc33','#ff99cc'],
            restore:[],
            canvas:[],
            //内存泄漏
            coords:new Map()
        }
    },
    async created(){
        this.lotteryKind = this.$store.state.common.lotteryKind
        this.onLoad()
    },
    mounted(){
    },
    methods: {
        async onLoad(){
            const res = await this.$HTTP.post(this, 'API_LOTTERY_LISTS_SPACE', { lotteryKind: this.lotteryKind, pageNo: '1', pageSize: '30', appendSpaceNum: '0' }, {}, true)
            this.list = res.data;
            await this.mkborder()
            
        },
        
        mkborder(){
            let canvas = document.getElementById('lotteryKind5') || document.getElementById('lotteryKind7');
            
            //获取设备宽度
            let deviceWidth = document.documentElement.clientWidth;
            this.deviceWidth = deviceWidth;
            //dpi导致字模糊
            let ratio = window.devicePixelRatio || 1;
            canvas.width = deviceWidth * ratio;
            //设置高度
            let size = this.list.length;
            //设置边框大小
            let rectW,rectH;
            if(this.lotteryKind == '5'){
                rectW = deviceWidth/8;
                rectH = rectW/0.8;
            }else{
                rectW = deviceWidth/10;
                rectH = rectW/0.8;
            }
            //存储rectW到边框中
            this.rectW = rectW;
            this.rectH = rectH;
            canvas.height = rectH*(size+0.5)*ratio;
            let c = canvas.getContext("2d");
            this.canvas = c
            //放大倍数
            c.scale(ratio,ratio);
            c.beginPath();
            c.strokeStyle = '#816bda';
            c.lineWidth = '6';
            c.rect(0,0,deviceWidth,rectH*(size+1));
            c.stroke();
            c.closePath();
            //设置字体
            c.font=`0.5rem Arial`;
            if(this.lotteryKind === '5'){
                c.fillText("期号",rectW*0.64,0.36*rectH);
                c.fillText("值",rectW*2.30,0.36*rectH);
            }else{
                c.fillText("期号",rectW*0.64,0.4*rectH);
                c.fillText("值",rectW*2.30,0.4*rectH);
            }
            //绘制间距不同的横线和竖线
            this.border(c,rectW,rectH,deviceWidth,size);
            this.mkheader(c,rectW,rectH);
            this.mkline(c,rectW,rectH,size,deviceWidth,canvas);
            //计算xy边界值
            this.computedXY(c,rectW,rectH,size);
        },
        //绘制两条粗线
        border(c,rectW,rectH,deviceWidth,size){
            this.setLineColor(c,rectW*2,0,rectW*2,rectH*(size+1),4);
            this.setLineColor(c,0,rectH/2,deviceWidth,rectH/2,4);
        },
        //绘制单元格信息
        mkheader(c,w,h){
            let header;
            if(this.lotteryKind === '5'){
                header = this.header;
            }else{
                header = this.header7;
            }
            let list = this.list;
            for(let i = 2;i<header.length;i++){
                c.fillText(header[i],w*i+w*1.36,0.38*h);
            }
            for(let x = 0;x<this.list.length;x++){
                //文本居中
                c.textAlign = 'center'
                c.fillText(list[x].periodText,w,h*1.1+h*x);
                c.fillText(list[x].lotteryVu,w*2.5,h*1.1+h*x);
                for(let i = 1;i < 8;i++){
                    c.fillText(eval("list[x].num"+i),w*(2.5+i),h*1.1+h*x);
                }
            }
        },
        //绘制线条
        mkline(c,w,h,size,deviceWidth,canvas){
            let restore;
            for(let x = 1;x < 9;x++){
                this.setLineColor(c,w*(x+1),0,w*(x+1),h*(size+1),1)
            }
            for(let y = 1;y<this.list.length+1;y++){
                if(y==1){
                    this.setLineColor(c,0,h*1.5*y,deviceWidth,h*1.5*y);
                }else{
                    this.setLineColor(c,0,h*y+h*0.5,deviceWidth,h*y+h*0.5);
                }
            }
            for(let y = 4;y<this.list.length;y+=4){
                this.setLineColor(c,0,h*y+h*0.5,deviceWidth,h*y+h*0.5,4);
            }
            //存储像素点在一个image上
            restore = [c.getImageData(0,0,canvas.width,canvas.height)]
            this.restore = restore
        },
        //获取对应位置的index
        getIndex(map,offsetX,offsetY){
            let rectW = this.rectW;
            if(offsetY){
                for(let [key,value] of map.entries()){
                    if(offsetY<value){
                        return key;
                    }
                }
            }else{
                for(let [key,value] of map.entries()){
                    if(offsetX<value && offsetX>rectW*2){
                        return key;
                    }
                }
            }
        },
        getXY(e,mapx,mapy,rectW){
            let obj = {};
            obj.offsetX = e.offsetX;
            obj.offsetY = e.offsetY;
            let x = this.getIndex(mapx,obj.offsetX);
            let y = this.getIndex(mapy,obj.offsetX,obj.offsetY);
            return {x,y};
        },
        computedXY(c,rectW,rectH,size){
            let canvas = document.getElementById('lotteryKind5') || document.getElementById('lotteryKind7')
            //构建列表宽高
            let offsetX = rectW*3;
            let offsetY = rectW/0.8+rectH/2;
            const mapx = new Map();
            const mapy = new Map();
            //存放坐标点
            let repetition = false;
            let deviceWidth = this.deviceWidth;
            let r,x1,y1;
            for(let x = 0;x <8;x ++){
                mapx.set(x,offsetX);
                offsetX = offsetX+rectW;
            }
            for(let y = 0;y <this.list.length;y++){
                mapy.set(y,offsetY);
                offsetY = offsetY + rectH;
            }
            canvas.onmousedown = (e)=>{
                let {x,y} = this.getXY(e,mapx,mapy);
                this.drawLogo(c,x,y,rectW,rectH);
                this.startObj.x = x;
                this.startObj.y = y;
                x1 = x;
                y1 = y;
            }

            canvas.onmouseup = (e)=>{
                let coords = this.coords;
                let {x,y} = this.getXY(e,mapx,mapy);
                let restore = this.restore
                this.endObj.x = x;
                this.endObj.y = y;
                let obj = {x,y};
                this.drawLine(c,x,y,rectW,rectH);
                this.drawLogo(c,x,y,rectW,rectH);
                
                let list = this.list;
                //变量控制是否可以去掉符号和数字
                let isChange = false
                restore[restore.length] = c.getImageData(0,0,canvas.width,canvas.height);
                if(x1 == x && y1 == y){
                    if(!isChange){
                        for(let [key,val] of coords.entries()){
                            if(JSON.stringify(val) == JSON.stringify(obj)){
                                repetition = true
                                coords.delete(key)
                                if(repetition == true){
                                    if(this.lotteryKind === '5'){
                                        r = deviceWidth/20+1;
                                    }else{
                                        r = deviceWidth/26+1;
                                    }
                                    c.beginPath();
                                    c.arc(rectW*2.5+rectW*x,rectH*(y+0.99),r,0,2*Math.PI);
                                    c.fillStyle = '#f8f8f8';
                                    c.fill();
                                    c.closePath();
                                    repetition = false;
                                    //文本居中
                                    c.textAlign = 'center';
                                    c.fillStyle = '#000';
                                    console.log(list);
                                    if(x == 0){
                                        c.fillText(list[y].lotteryVu,rectW*2.5,rectH*1.1+rectH*y);
                                    }else{
                                        c.fillText(eval("list[y].num"+x),rectW*(2.5+x),rectH*1.1+rectH*y);
                                    }                                        
                                }
                                return;
                            }
                        }
                    }
                }
                // else{
                //     isChange = false
                //     console.log(isChange);
                // }
                coords.set(coords.size,obj)
                this.coords = coords
            }
        },
        //绘制logo
        drawLogo(c,x,y,rectW,rectH){
            let text,r;
            let color = this.color;
            //圆的半径大小动态变化
            let deviceWidth = this.deviceWidth;
            if(this.lotteryKind === '5'){
                r = deviceWidth/20
            }else{
                r = deviceWidth/26
            }
            c.beginPath();
            c.arc(rectW*2.5+rectW*x,rectH*(y+0.99),r,0,2*Math.PI);
            c.fillStyle = color;
            c.fill();
            c.save()
            c.closePath();
            if(x==0){
                text = this.list[y].lotteryVu;
                c.beginPath();
                c.fillStyle = '#fff';
                c.fillText(text,rectW*2.5+rectW*x,rectH*(y+1.1));
                c.save()
                c.closePath();
            }else{
                let content = this.list[y].lotteryContent;
                let length = content.length;
                let kind = this.list[y].lotteryKind;
                if(kind == length){
                    text = content.charAt(x-1);
                    c.beginPath();
                    c.fillStyle = '#fff';
                    c.fillText(text,rectW*2.5+rectW*x,rectH*(y+1.1));
                    c.save();
                    c.closePath();
                }else{
                    if(x == length-1){
                        text = content.charAt(length-2)+content.charAt(length-1);
                    }else{
                        text = content.charAt(x-1);
                    }
                    c.beginPath();
                    c.fillStyle = '#fff';
                    c.textAlign = 'center'
                    c.fillText(text,rectW*2.5+rectW*x,rectH*(y+1.1));
                    c.save();
                    c.closePath();
                }
            }
            c.restore()
        },
        //绘制线条
        drawLine(c,x,y,rectW,rectH){
            let type = this.lineType;
            let startObj = this.startObj;
            let endObj = this.endObj;
            let x1 = this.startObj.x;
            let y1 = this.startObj.y;
            let x2 = this.endObj.x;
            let y2 = this.endObj.y;
            let r = 0;
            let offsetX,offsetY,angle = 0;
            x1 = rectW*2.5+rectW*x1;
            y1 = rectH*(y1+1);
            x2 = rectW*2.5+rectW*x2;
            y2 = rectH*(y2+1);
            //共有代码
            offsetX = Math.abs(x2 - x1);
            offsetY = Math.abs(y2 - y1);
            angle = Math.atan(offsetX/offsetY)/(Math.PI / 180);
            angle = angle /180*( Math.PI);
            //动态半径计算偏移量
            let deviceWidth = this.deviceWidth;
            let offsetX1,offsetY1;
            if(this.lotteryKind === '5'){
                let r = deviceWidth/20;
                offsetX1 = r*Math.sin(angle);
                offsetY1 = r*Math.cos(angle);
            }else{
                let r = deviceWidth/26;
                offsetX1 = r*Math.sin(angle);
                offsetY1 = r*Math.cos(angle);
            }
            if(startObj.x<endObj.x){
                if(startObj.y<endObj.y){
                    offsetX1=offsetX1
                    offsetY1=offsetY1
                }else if(startObj.y>endObj.y){
                    offsetX1=offsetX1
                    offsetY1=-offsetY1
                }
                else{
                    offsetX1=offsetX1
                }
            }
            else if(startObj.x>endObj.x){
                offsetX1=-offsetX1
                offsetY1=-offsetY1
                if(startObj.y<endObj.y){
                    offsetX1=offsetX1
                    offsetY1=-offsetY1
                }else if(startObj.y>endObj.y){
                    offsetX1=offsetX1
                    offsetY1=offsetY1
                }
                else{
                    offsetX1=offsetX1
                }
            }
            else{
                if(startObj.y<endObj.y){
                    offsetY1=offsetY1
                }else{
                    offsetY1=-offsetY1
                }
            }

            if(type == 'straight'){
                this.setLineColor(c,x1+offsetX1,y1+offsetY1,x2,y2);
                startObj = {};
                endObj= {}  
            }else{
                let x = Math.abs(x2 - x1);
                let y = Math.abs(y2 - y1);

                if(y==0){
                    r=y1
                    console.log(y1);
                }else if(x==0){
                    r=y1;
                }

                if(y1>y2){
                    r = y1+10
                }
                else{
                    r = y1-10
                }
                let color = this.color;
                c.beginPath();
                c.moveTo(x1+offsetX1*0.8,y1+offsetY1*0.8);
                c.bezierCurveTo(x1,r,x2,r,x2,y2);
                c.strokeStyle = color;
                c.stroke();
                c.save();
                c.closePath();
                startObj = {};
                endObj= {}
            }
        },
        //切换绘制颜色
        checkColor(val){
            this.color = val
        },
        //绘制线条颜色
        setLineColor(c,x1,y1,x2,y2,lineWidth){
            let color1 = '#2715cb';
            let width = lineWidth
            color1 = this.color || color1;
            c.beginPath();
            c.moveTo(x1,y1);
            c.strokeStyle = color1;
            c.lineWidth = width;
            c.lineTo(x2,y2);
            c.stroke();
            c.save();
            c.closePath();
        },
        unfold(){
            this.fold = !this.fold;
        },
        //切换线条
        chooseLine(val){
            this.lineType = val
        },
        back(){
            let restore = this.restore
            let canvas = this.canvas
            if(restore.length>1){
                canvas.putImageData(restore[restore.length-2],0,0)
                restore.length -- ;
                this.coords = new Map()
            }
        },
        remove(){
            let restore = this.restore
            let canvas = this.canvas
            if(restore.length>1){
                canvas.putImageData(restore[restore.length-restore.length],0,0)
                restore.length =restore.length - restore.length+1;
                this.coords = new Map()
                console.log(this.coords);
            }
        }
    }
}
</script> 
<style lang="less" scoped>
    .lotteryKind5,.lotteryKind7{
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
    }
    #lotteryKind5,#lotteryKind7{
        width: 100%;
        height: 100%;
    }
    .btnGroup{
        width: 20%;
        height: 140px;
        overflow: hidden;
        position: fixed;
        bottom: 10px;
        left: 16px;
        padding-bottom: 2px;
    }
    .btn{
        float: left;
        border: 1px solid #3366ff;
        border-radius: 40%;
        height: 0.8rem;
        margin-right: 10px;
        padding-left: 1px;
    }
    .btn img{
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
    }
    .btnGroup1{
        width: 20px;
        height: 100px;
        margin:0 0 10px 5px;
        transform: translateY(160px);
        transition: all linear .3s;
    }
    .btnGroup1 ul{
        width: 100%;
        height: 100%;
        border: 1px solid #3366ff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5px;
    }
    .btnGroup1 ul li{
        width: 18px;
        height: 18px;
        margin-bottom: 5px;
        border:1px solid #3366ff;
        border-radius: 50%;
    }
    .btnGroup1 ul li span{
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin: 0.026rem 0 0 0.028rem;
    }
    .btnGroup1 ul li:first-child span{
        background-color: #2715cb;
    }
    .btnGroup1 ul li:nth-child(2) span{
        background-color: #3366ff;
    }
    .btnGroup1 ul li:nth-child(3) span{
        background-color: #33cc33;
    }
    .btnGroup1 ul li:nth-child(4) span{
        background-color: #ff99cc;
    }
    .btnGroup2{
        display: inline-flex;
        align-items: center;
        justify-content: space-around;
        background-color: #ddd;
        border: 1px solid #3366ff;
        width: 20%;
        height: 26px;
        border-radius: 12px;
        transform: translateX(-140px);
        transition: all linear .3s;
        position: fixed;
        bottom: 10px;
        left: 52px;
    }
    .btnGroup2 .line{
        width: 20px;
        height: 20px;
        border-radius: 16px;
        text-align: center;
        line-height: 20px;
        background-color: #fff;
    }
    .btnGroup2 .line img{
        width: 14px;
        height: 14px;
        padding-top: 3px;
    }
    .fold1{
        transform: translateY(0);
    }
    .fold2{
        transform: translateX(0);
    }
    .delete{
        display: inline-block;
        width: 20%;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        bottom: 10px;
        right: 10px;
    }
    .back img,.delete img{
        width: 0.8rem;
        height: 0.8rem;
    }
</style>